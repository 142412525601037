<template>
    <div>
        <loading-indicator :title="$t('global.loadingMessage.loading')" />
    </div>
</template>
<script>
    import { appSessionStorage, localStorageKey, saveDebugLog } from '@/utils/storage'
    import LoadingIndicator from '@/components/LoadingIndicator'
    import { initializeLogRocket } from '@/utils/crashlyticsUtils'
    import { logger } from '@/utils/logger'
    import { RouteNames, RoutePaths } from '@/routes/router'
    import { mapGetters, mapMutations, mapActions } from 'vuex'
    import { runWithRetryLogic } from '@/services/http-client'
    import { navigation } from '@/mixins/navigation'
    import router from '../routes/router'

    export default {
        name: 'Splash',
        components: {
            loadingIndicator: LoadingIndicator,
        },
        created: function () {
            window.loadRootPage = this.loadRootPage
            this.setIsWebView(true)
        },
        mixins: [navigation],
        computed: {
            ...mapGetters([
                'currentRootPath',
                'isEligibleToPerformPayItForwardTipping',
                'showEnrollAutoPayAndKeepAprDiscount',
                'alreadyShownEnrollInAutoPayAndKeepAprDiscount',
                'isCryptoProduct',
                'deviceInfo',
            ]),
        },
        mounted() {
            logger.info('mSite webview splash...')
            window.location.href = '/splashMounted'
        },
        methods: {
            ...mapActions(['updateAccountOverview']),
            ...mapMutations(['setIsWebView', 'setSingleWebView', 'setCurrentRootPath', 'updateDeeplinkPath', 'enrollAutoPayAndKeepAprDiscountShown', 'setDeviceCapabilities', 'setDeviceInfo']),
            loadRootPage: async function (infoJson) {
                const info = JSON.parse(infoJson)
                this.setDeviceCapabilities(info.deviceCapabilities)

                // single webview native app starts at /login not any of the tabs
                if (info['rootViewName'].startsWith(RoutePaths.LOGIN)) {
                    appSessionStorage.clear()
                    saveDebugLog(localStorageKey.nativeInjectionLog, `/splash, native to web injection, json: ${JSON.stringify(infoJson)}`)
                    this.setSingleWebView(true)
                    this.setDeviceInfo(info['deviceInfo'])
                    appSessionStorage.setItem(localStorageKey.coreCardCustomerId, info.coreCardCustomerId)
                    appSessionStorage.setItem(localStorageKey.creditCardCustomerId, info.creditCardCustomerId)
                    await this.$router.replace(info['rootViewName'])
                    return
                }

                appSessionStorage.setItem(localStorageKey.coreCardCustomerId, info.coreCardCustomerId)
                appSessionStorage.setItem(localStorageKey.creditCardCustomerId, info.creditCardCustomerId)
                appSessionStorage.setItem(localStorageKey.deviceGuid, info.deviceGuid)
                appSessionStorage.setItem(localStorageKey.sessionId, info.sessionId)
                appSessionStorage.setItem(localStorageKey.jwtToken, info.accessJwt)
                appSessionStorage.setItem(localStorageKey.sessionAccessJWT, info.sessionAccessJwt)
                logger.info(`${RouteNames.SPLASH} -- native injected json: ${infoJson}`)

                const { root, deeplink } = this.parseFullPathForRootAndDeeplink(info['rootViewName'])
                this.setCurrentRootPath(`/${root}`)
                logger.info(`${RouteNames.SPLASH} -- root: ${root}, deeplink: ${deeplink}`)

                const expectedPaths = [RoutePaths.ACTIVITY.toString(), RoutePaths.STATEMENTS.toString(), RoutePaths.CARD.toString()]
                if (!expectedPaths.includes(this.currentRootPath)) {
                    logger.error(`${RouteNames.SPLASH} -- \`Expected paths: ${JSON.stringify(expectedPaths)}, current path: ${this.currentRootPath}\``)
                    return
                }

                await this.maybeInitializeLogRocket(info)

                this.$nextTick(async () => {
                    logger.info(`${RouteNames.SPLASH} -- start updateAccountOverview`)
                    await runWithRetryLogic(() => this.updateAccountOverview(), 3, 1000)
                    logger.info(`${RouteNames.SPLASH} -- end updateAccountOverview`)
                    logger.log(`${RouteNames.SPLASH} -- sessionStorage ${JSON.stringify(window.sessionStorage)}`)

                    if (this.isCryptoProduct) {
                        if (deeplink) {
                            logger.log(`${RouteNames.SPLASH} -- ${RoutePaths.SPLASH} to deeplink page, /${deeplink}`)
                            await this.$router.replace({ path: deeplink, query: { fromDeeplink: 'true' } })
                        } else if (this.currentRootPath === RoutePaths.ACTIVITY && this.isCryptoProduct) {
                            // TODO Crypto: Only show if you have not deposited Crypto
                            logger.log(`${RouteNames.SPLASH} -- ${RoutePaths.SPLASH} to ${RoutePaths.DEPOSIT_CRYPTO}`)
                            await this.$router.replace(RoutePaths.DEPOSIT_CRYPTO)
                        } else {
                            logger.log(`splash to root page, /${this.currentRootPath}`)
                            await this.returnToRoot()
                        }
                    } else {
                        if (deeplink) {
                            logger.log(`${RouteNames.SPLASH} -- ${RoutePaths.SPLASH} to deeplink page, /${deeplink}`)
                            await this.$router.replace({ path: deeplink, query: { fromDeeplink: 'true' } })
                        } else if (this.currentRootPath === RoutePaths.ACTIVITY && this.isEligibleToPerformPayItForwardTipping) {
                            logger.log(`${RouteNames.SPLASH} -- ${RoutePaths.SPLASH} to ${RoutePaths.PAY_IT_FORWARD_SEND_TIP}`)
                            await this.$router.replace(RoutePaths.PAY_IT_FORWARD_SEND_TIP)
                        } else if (this.currentRootPath === RoutePaths.ACTIVITY && this.showEnrollAutoPayAndKeepAprDiscount && !this.alreadyShownEnrollInAutoPayAndKeepAprDiscount) {
                            this.enrollAutoPayAndKeepAprDiscountShown()
                            await router.push(RoutePaths.SETUP_AUTOPAY)
                        } else {
                            logger.log(`splash to root page, /${this.currentRootPath}`)
                            await this.returnToRoot()
                        }
                    }
                })
            },
            maybeInitializeLogRocket: function (info) {
                if (!info.applicantId || !info.loanApplicationId || !info.deviceGuid) {
                    logger.info(`Not enough information to initialize logRocket, ${JSON.stringify(info)}`)
                    return
                }
                const userTraits = {
                    applicantId: info.applicantId,
                    loanApplicationId: info.loanApplicationId,
                    coreCardCustomerId: info.coreCardCustomerId,
                    deviceGuid: info.deviceGuid,
                }
                logger.info(`${RouteNames.SPLASH} -- init logRocket session with traits: ${JSON.stringify(userTraits)}`)
                initializeLogRocket(info.sessionId, userTraits)
            },
            parseFullPathForRootAndDeeplink: function (path) {
                logger.info(`parseFullPathForRootAndDeeplink ${RouteNames.SPLASH} -- json.rootViewName: ${path}`)
                // android: /card/cashOut  -> Array ["", "card", "cashOut"]
                // iOS: card/cashOut -> Array ["card", "cashOut"]
                var fullPath = path
                if (fullPath.startsWith('/')) {
                    fullPath = fullPath.slice(1)
                }
                logger.info(`parseFullPathForRootAndDeeplink ${RouteNames.SPLASH} -- json.rootViewName normalized: ${fullPath}`)
                const pathComponents = fullPath.split('/')
                logger.info(`parseFullPathForRootAndDeeplink ${RouteNames.SPLASH} -- pathComponents: ${pathComponents}`)
                const root = pathComponents[0]
                const deeplink = pathComponents.length > 1 ? pathComponents.slice(1).join('/') : null
                logger.info(`parseFullPathForRootAndDeeplink ${RouteNames.SPLASH} -- root: ${root}, deeplink: ${deeplink || 'none'}`)
                return {
                    root,
                    deeplink,
                }
            },
        },
    }
</script>
